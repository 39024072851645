import {format} from "date-fns";
import React, {useCallback} from "react";
import {DepositStatusList} from "../../common/Constant";
import {InputType} from "../../common/FormRenderer";
import NotificationPopup from "../../common/NotificationPopup";
import {DepositStatus, useAddChangeRecordMutation, useUpdateDepositMutation,} from "../../generated/graphql";
import CreateView from "../custom/CreateView";
import {omit} from "lodash";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {Box} from "@material-ui/core";
import DepositRecordTable from "./DepositRecordTable";

export default ({ deposit, amMode = true , orderItem}) => {
    const [updateDeposit] = useUpdateDepositMutation();
    const [addChangeRecord] = useAddChangeRecordMutation();
    const onSubmit = useCallback(
        async (
            updatedDeposit,
            { resetForm },
            setOpen
        ) => {
            try {
                let newDeposit;
                if (!amMode) {
                    newDeposit = await updateDeposit({
                        variables: {
                            deposit: amMode
                                ? {...omit(updatedDeposit, ['id']), status: DepositStatus.Changed}
                                : {
                                    status: updatedDeposit.status,
                                },
                            id: updatedDeposit.id,
                        },
                        refetchQueries: ["getDepositList", "getOrderItem"],
                    });
                }
                else {

                    newDeposit = await addChangeRecord({
                        variables: {
                            createDepositChangeDto: {
                                depositId: updatedDeposit.id,
                                startDate: updatedDeposit.startDate,
                                endDate: updatedDeposit.endDate,
                                description: updatedDeposit.description,
                                amount: updatedDeposit.amount,
                            },
                        },
                        refetchQueries: ["getDepositList", "getOrderItem"],
                    })
                }
                NotificationPopup.success(amMode ? `送出異動申請`:`修改完成`);
                return newDeposit.data?.updateDeposit;
            } catch (e) {
                NotificationPopup.error(
                    `Failed to update deposit ${e.messaeg}`
                );
                console.error(e);
            } finally {
                resetForm();
                setOpen(false);
            }
        },
        [updateDeposit]
    );
    return (
        <CreateView
            title={amMode ? "異動發稿" : "修改發稿"}
            columns={
                amMode
                    ? [
                        {
                            name: "id",
                            label: "ID",
                            value: deposit.id,
                            type: InputType.hidden,
                        },
                        {
                            name: "startDate",
                            label: "開始時間",
                            type: InputType.date,
                            value: format(
                                new Date(deposit.startDate),
                                "yyyy-MM-dd"
                            ),
                            min: orderItem.startDate,
                            max: orderItem.endDate,
                        },
                        {
                            name: "endDate",
                            label: "結束時間",
                            type: InputType.date,
                            value: format(
                                new Date(deposit.endDate),
                                "yyyy-MM-dd"
                            ),
                            min: orderItem.startDate,
                            max: orderItem.endDate,
                        },
                        {
                            name: "amount",
                            label: "異動金額",
                            type: InputType.number,
                        },
                        {
                            name: "description",
                            label: "異動說明",
                        },
                        {
                            name: "custom",
                            label: "異動紀錄",
                            type: InputType.custom,
                            renderCell: (
                                <div style={{marginTop: 10, marginBottom: 10}}>
                                    <DepositRecordTable deposit={deposit} />
                                </div>
                            ),
                        },
                    ]
                    : [
                        {
                            name: "id",
                            label: "ID",
                            value: deposit.id,
                            type: InputType.hidden,
                        },
                        {
                            name: "description",
                            label: "異動說明",
                            type: InputType.custom,
                            renderCell: (
                                <div
                                    style={{
                                        marginBottom: 10,
                                    }}
                                >
                                    異動原因：
                                    {deposit.description}
                                </div>
                            ),
                        },
                        {
                            name: "status",
                            label: "狀態",
                            type: InputType.select,
                            options: DepositStatusList,
                            value: deposit.status,
                        },
                    ]
            }
            onSubmit={onSubmit}
            submitTitle={"儲存"}
            queryEntity={"deposit"}
        />
    );
};
