import React from "react";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { format } from "date-fns";
import {Deposit, DepositStatus} from "../../generated/graphql";

interface DepositChange {
    id: number;
    createdAt: string;
    amount?: number;
    startDate?: string;
    endDate?: string;
    description?: string;
    verified?: boolean;
    verifiedAt?: string;
}

interface DepositRecordTableProps {
    deposit: Deposit;
}

const DepositRecordTable: React.FC<DepositRecordTableProps> = ({ deposit }) => {
    const columns: GridColDef[] = [
        {
            field: "createdAt",
            headerName: "建立時間",
            width: 180,
            valueGetter: (params) =>
                format(new Date(params.row.createdAt), "yyyy-MM-dd HH:mm:ss"),
        },
        {
            field: "amount",
            headerName: "金額",
            width: 100,
            valueGetter: (params) => params.row.amount ?? "-",
        },
        {
            field: "startDate",
            headerName: "開始時間",
            width: 150,
            valueGetter: (params) =>
                params.row.startDate
                    ? format(new Date(params.row.startDate), "yyyy-MM-dd")
                    : "-",
        },
        {
            field: "endDate",
            headerName: "結束時間",
            width: 150,
            valueGetter: (params) =>
                params.row.endDate
                    ? format(new Date(params.row.endDate), "yyyy-MM-dd")
                    : "-",
        },
        { field: "description", headerName: "異動說明", width: 200 },
        {
            field: "verified",
            headerName: "審核",
            width: 100,
            valueGetter: (params) => {
                const isLatestChange =
                    params.row.id ===
                    deposit.changes[deposit.changes.length - 1].id;
                const isPendingOrChanged = [
                    DepositStatus.Pending,
                    DepositStatus.Changed,
                ].includes(deposit.status);

                return params.row.verified
                    ? "通過"
                    : isLatestChange && isPendingOrChanged
                        ? "-"
                        : "不通過";
            },
        },
        {
            field: "verifiedAt",
            headerName: "審核時間",
            width: 150,
            valueGetter: (params) =>
                params.row.verifiedAt
                    ? format(new Date(params.row.verifiedAt), "yyyy-MM-dd HH:mm:ss")
                    : "",
        },
    ];

    return (
        <DataGridPro
            hideFooter={true}
            autoHeight={true}
            rows={deposit.changes}
            columns={columns}
            getRowId={(row) => row.id}
        />
    );
};

export default DepositRecordTable;
