import React, {useCallback, useEffect, useState} from "react";
import NotificationPopup from "../../common/NotificationPopup";
import {
    InvoiceStatus,
    Order,
    OrderStatus,
    useCreateInvoicesMutation, useCreateLogMutation,
    useUpdateOrderStatusMutation,
} from "../../generated/graphql";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from "@material-ui/core";
import {filter, find, keys, map, range} from "lodash";
import {format} from "date-fns";
import {CurrencyMap} from "../../common/Constant";
import {NumberFormatCustom} from "../../common/FormRenderer";
import {useUser} from "../../Auth";

const getPeriodInvoices = (order, checked, amounts, date, period, description, note) => {
    let invoices: Array<any> = [];
    range(period).map((index) => {
        let nextDate = new Date(date);
        nextDate.setMonth(nextDate.getMonth() + index);
        invoices.push({
            orderId: order.id,
            items: map<{ orderItemId: string | number, checked: boolean, amount: number }>(
                filter<{ orderItemId: string | number, checked: boolean, amount: number }>(
                    map(checked, (val, key) => ({
                        orderItemId: key,
                        checked: val,
                        amount: parseFloat(amounts[key]) / period,
                    })),
                    ({checked}) => checked
                ),
                ({amount, orderItemId}) => ({
                    amount,
                    orderItemId,
                })
            ),
            date: format(nextDate, "yyyy-MM-dd"),
            currency: order.currency,
            description: description,
            note: note,
        })
    })
    return invoices
}

export default ({order, total}: { order: Order; total: number }) => {
    const [createInvoices] = useCreateInvoicesMutation();
    const [updateOrderStatus] = useUpdateOrderStatusMutation();
    const [open, setOpen] = useState(false);
    const [checked, setChecked] = useState({});
    const [amounts, setAmounts] = useState({});
    const [date, setDate] = useState(format(new Date(), "yyyy-MM-dd"));
    const [installment, setInstallment] = useState(false);
    const [period, setPeriod] = useState<number | string>(1);
    const [description, setDescription] = useState<string | null>(`${order.name},${order.number}`);
    const [note, setNote] = useState<string>();
    const user = useUser();
    const [createLog] = useCreateLogMutation();
    useEffect(() => {
        const init = {};
        order.items.map((item) => {
            init[item.id] = 0;
        });
        setAmounts(init);
    }, []);
    const onSubmit = useCallback(async () => {
        if (remaindAmount < 0) {
            NotificationPopup.error(`超過可開發票金額。`);
            return;
        }
        try {
            // let targetDate = new Date(date);
            // let today = new Date();
            // if (differenceInCalendarDays(targetDate, today)<1) {
            //     NotificationPopup.error(`不能開立今天或以前的發票。`);
            //     return;
            // }
            const newInvoices = await createInvoices({
                variables: {
                    invoices: getPeriodInvoices(order, checked, amounts, date, period, description, note),
                },
            });
            NotificationPopup.success(`建立完成`);
            await updateOrderStatus({
                variables: {
                    id: order.id,
                    status: {
                        status: OrderStatus.InvoiceRequested,
                    },
                },
                refetchQueries: ["getOrder"],
            });
            await createLog({
                variables: {
                    log: {
                        entityId: newInvoices.data?.createInvoices[0].id as string,
                        userId: user!.id as string,
                        queryAction: "申請開立發票",
                        queryEntity: "invoice",
                        queryMessage:
                            `開立發票日期: ${date}, 發票備註欄: ${description}, 尾差註記: ${note ? note : "無"} 分${period}期, 項目： [`+
                            order?.items?.filter((orderItem)=>amounts[orderItem.id]!=null)
                                .map((orderItem)=>`${orderItem.product?.name}: ${amounts[orderItem.id]}`).join(', ')+"]",
                    }
                }
            });
        } catch (e) {
            NotificationPopup.error(`新增發票發生問題: ${e.message}`);
            console.error(e);
        } finally {
            setOpen(false);
        }
    }, [createInvoices, setOpen, checked, amounts, date, period, description, note]);

    let selectedAmount = 0;
    keys(amounts).map((id) => {
        let v = parseInt(amounts[id])
        selectedAmount += isNaN(v) ? 0 : v;
    });
    let remaindAmount = total - selectedAmount;
    return (
        <div>
            <Dialog
                maxWidth={"md"}
                open={open}
                onClose={() => {
                    setChecked({});
                    setAmounts({});
                    setOpen(false);
                }}
            >
                <DialogTitle>建立發票</DialogTitle>
                <DialogContent>
                    <TextField
                        label="開立發票日期"
                        type="date"
                        key={date}
                        value={date}
                        onChange={(e) => {
                            setDate(e.target.value);
                        }}
                    ></TextField>
                    <br/>
                    <TextField
                        label="發票備註欄"
                        onChange={(e) => {
                            setDescription(e.target.value);
                        }}
                        style={{width: "100%"}}
                        defaultValue={`${order.name},${order.number}`}
                    ></TextField>
                    <br/>
                    <TextField
                        label="尾差註記"
                        onChange={(e) => {
                            setNote(e.target.value);
                        }}
                        style={{width: "100%"}}
                    ></TextField>
                    <div style={{display: "flex", alignItems: "baseline"}}>
                        <input
                            type={"checkbox"}
                            onChange={(e) => {
                                setInstallment(e.target.checked);
                                setPeriod(1);
                            }}
                            style={{margin: 15}}
                        />
                        分期開立：
                        <TextField
                            disabled={!installment}
                            style={{width: 50}}
                            inputProps={{style: {padding: 0, textAlign: 'center'}}}
                            value={period}
                            onChange={(e) => {
                                setPeriod(e.target.value)
                            }}
                            onBlur={(e) => {
                                let v = parseInt(e.target.value)
                                if (isNaN(v) || v == 0)
                                    setPeriod(1)
                                else
                                    setPeriod(v)
                            }}
                        ></TextField>
                        期
                    </div>
                    <div style={{marginBottom: 10, marginTop: 10}}>
                        幣別：{CurrencyMap[order.currency]}
                    </div>
                    <div>剩餘可開發票金額：{remaindAmount?.toLocaleString()}</div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>細項ID</TableCell>
                                <TableCell>產品</TableCell>
                                <TableCell>購買金額</TableCell>
                                <TableCell>服務費</TableCell>
                                <TableCell>剩餘開立金額</TableCell>
                                <TableCell>發票金額</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {order?.items?.map(
                                ({id, product, totalAmount, serviceFee}) => {
                                    const remaindAmount = totalAmount! + serviceFee! -
                                        order.invoices.reduce((value, invoice) => {
                                            if (invoice.status == InvoiceStatus.Canceled) {
                                                return value;
                                            }
                                            else {
                                                const invoiceItem = find(invoice.items, (invoiceItem) => id == invoiceItem.orderItem.id);
                                                return value + (invoiceItem ? invoiceItem.amount : 0);
                                            }
                                        }, 0) - amounts[id]
                                    return (
                                        <TableRow key={id}>
                                            <TableCell>
                                                <input
                                                    type={"checkbox"}
                                                    value={checked[id]}
                                                    onChange={(e) => {
                                                        if (!e.target.checked) {
                                                            setAmounts((c) => ({
                                                                ...c,
                                                                [id]: 0,
                                                            }));
                                                        }
                                                        setChecked((c) => ({
                                                            ...c,
                                                            [id]: e.target.checked,
                                                        }));
                                                    }}
                                                />
                                            </TableCell>
                                            {[
                                                id,
                                                product?.name,
                                                totalAmount,
                                                serviceFee,
                                            ].map((v) => (
                                                <TableCell key={v}>
                                                    {(v as string)?.toLocaleString()}
                                                </TableCell>
                                            ))}
                                            <TableCell style={{color: remaindAmount < 0 ? "red" : ""}}>
                                                {
                                                    remaindAmount.toLocaleString()
                                                }
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    disabled={!checked[id]}
                                                    value={amounts[id]}
                                                    onChange={(e) => {
                                                        setAmounts((c) => ({
                                                            ...c,
                                                            [id]: e.target.value,
                                                        }));
                                                    }}
                                                    InputProps={{
                                                        inputComponent: NumberFormatCustom as any,
                                                    }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                }
                            )}
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>未稅總和</TableCell>
                                <TableCell>{selectedAmount.toLocaleString()}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>稅額</TableCell>
                                <TableCell>{Math.round(selectedAmount * 0.05).toLocaleString()}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>總和</TableCell>
                                <TableCell>{Math.round(selectedAmount * 1.05).toLocaleString()}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        onClick={() => {
                            onSubmit();
                        }}
                    >
                        送出
                    </Button>
                    <Button
                        variant={"contained"}
                        color={"secondary"}
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        取消
                    </Button>
                </DialogActions>
            </Dialog>

            <Button
                variant={"contained"}
                color={"secondary"}
                onClick={() => setOpen(true)}
                style={{marginBottom: 15}}
            >
                申請開立發票
            </Button>
        </div>
    );
};
