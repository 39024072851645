import React, {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography,} from "@material-ui/core";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {format} from "date-fns";
import {DepositStatus, useReviewDepositMutation} from "../../generated/graphql";
import NotificationPopup from "../../common/NotificationPopup";
import DepositRecordTable from "./DepositRecordTable"; // 使用您生成的 Hook

export default function AuditDepositButton({ deposit }) {
    const [open, setOpen] = useState(false);

    // 调用 reviewDeposit Mutation 的生成 Hook
    const [reviewDeposit, { loading }] = useReviewDepositMutation();

    const handleReview = async (approved: boolean) => {
        try {
            await reviewDeposit({
                variables: {
                    id: deposit.id,
                    approved,
                },
                refetchQueries: ['getDepositList'],
            });
            NotificationPopup.success(`審核完成`);
            setOpen(false);
        } catch (e) {
            NotificationPopup.error(
                `送出審核失敗： ${e.messaeg}`
            );
        }
    };

    return (
        <div>
            <Dialog open={open} fullWidth={true} maxWidth="md">
                <DialogTitle>
                    <Typography variant="h3" align="center">
                        審核發稿
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    {/* DataGrid 表格 */}
                    <div style={{marginTop: 10, marginBottom: 10}}>
                        <DepositRecordTable deposit={deposit} />
                    </div>
                </DialogContent>

                {/* 底部按鈕 */}
                <DialogActions style={{ paddingLeft: 24, paddingRight: 24, justifyContent: "space-between" }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleReview(false)}
                        disabled={loading}
                    >
                        不通過
                    </Button>
                    <div style={{ display: "flex", gap: "16px" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleReview(true)}
                            disabled={loading}
                        >
                            通過
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => setOpen(false)}
                            disabled={loading}
                        >
                            取消
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>

            {/* 按鈕 */}
            <Button
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
            >
                審核發稿
            </Button>
        </div>
    );
}
