import Page from "../../../components/Page";
import { Container, Grid, Tabs, Tab } from "@material-ui/core";
import React, {useState} from "react";
import RecordTable from "./RecordTable";
import MetaRecordTable from "./MetaRecordTable";
import {useTabIndex} from "../../../common/Util";

export default () => {
    const [tabIndex, setTabIndex] = useTabIndex();

    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
    };
    return (
        <Page title={"record page"}>
            <Container maxWidth={false} style={{ padding: "30px"}}>
                <Tabs value={tabIndex} onChange={handleChange}>
                    <Tab label="Google報表紀錄" />
                    <Tab label="Meta報表紀錄" />
                </Tabs>
                <Grid container spacing={2}>
                    {tabIndex === 0 && <RecordTable />}
                    {tabIndex === 1 && <MetaRecordTable />}
                </Grid>
            </Container>
        </Page>
    )
}