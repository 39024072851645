import React, {useState, useMemo, useEffect} from "react";
import {
    Button,
    Container,
    Grid,
    Typography,
    TextField,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Checkbox,
    ListItemText,
    Box, CircularProgress,
} from "@material-ui/core";
import { useGetOrdersForCsProjectQuery } from "../../generated/graphql";
import Page from "../../components/Page";
import CsProjectTable from "./CsProjectTable";

// 工具函數：根據月份生成第一天和最後一天
const getMonthFirstDay = (month: string): string => {
    const [year, mon] = month.split("-");
    return new Date(Number(year), Number(mon) - 1, 1).toISOString();
};

const getMonthLastDay = (month: string): string => {
    const [year, mon] = month.split("-");
    return new Date(Number(year), Number(mon), 0).toISOString();
};

// 檢查日期是否有交集
const isDateOverlap = (
    itemStartDate: string | null,
    itemEndDate: string | null,
    filterStartDate: Date | null,
    filterEndDate: Date | null
) => {
    if (!itemStartDate || !itemEndDate || !filterStartDate || !filterEndDate) {
        return false;
    }

    const itemStart = new Date(itemStartDate);
    const itemEnd = new Date(itemEndDate);
    const filterStart = filterStartDate;
    const filterEnd = filterEndDate;

    // 確保所有日期都是有效的
    if (isNaN(itemStart.getTime()) || isNaN(itemEnd.getTime())) return false;

    // `item` 的日期區間必須與 `filter` 的區間有重疊
    const overlap = itemStart <= filterEnd && itemEnd >= filterStart;

    if (overlap) {
        console.log("✅ 日期重疊，符合篩選條件");
        console.log("itemStart:", itemStart.toISOString());
        console.log("itemEnd:", itemEnd.toISOString());
        console.log("filterStart:", filterStart.toISOString());
        console.log("filterEnd:", filterEnd.toISOString());
    }

    return overlap;
};



const CsProjectView: React.FC = () => {
    const { data, loading, error } = useGetOrdersForCsProjectQuery();

    // 設置當前月份的預設值
    const currentMonth = new Date();
    const defaultMonth = `${currentMonth.getFullYear()}-${String(
        currentMonth.getMonth() + 1
    ).padStart(2, "0")}`;

    const [filters, setFilters] = useState({
        assignees: [] as string[],
        customers: [] as string[],
        products: [] as string[],
        startMonth: defaultMonth, // 預設為本月
        endMonth: defaultMonth,   // 預設為本月
    });

    const [searchQuery, setSearchQuery] = useState({
        assignees: "",
        customers: "",
        products: "",
    }); // 為每個篩選器單獨設置搜尋狀態
    const [isProcessing, setIsProcessing] = useState(false);
    const [computedData, setComputedData] = useState<any[]>([]);


    // 動態生成選項
    const dynamicOptions = useMemo(() => {
        const projects = data?.getOrdersForCsProject || [];
        const { startMonth, endMonth } = filters;

        // 轉換篩選的開始和結束月份為日期
        const startDate = startMonth ? new Date(getMonthFirstDay(startMonth)) : null;
        const endDate = endMonth ? new Date(getMonthLastDay(endMonth)) : null;

        // 先用 `startMonth ~ endMonth` 過濾符合日期的 `order`
        const filteredByDate = projects.filter((order) =>
            order.processedItems?.some((item) => isDateOverlap(item.startDate, item.endDate, startDate, endDate))
        );

        // 動態計算可選項
        const allAssignees = new Set<string>();
        const allCustomers = new Set<string>();
        const allProducts = new Set<string>();

        // **不受其他篩選條件影響，只根據日期篩選**
        filteredByDate.forEach((order) => {
            if (order.assignedSalesUser?.name) {
                allAssignees.add(order.assignedSalesUser.name);
            }
            if (order.customer?.name) {
                allCustomers.add(order.customer.name);
            }
            order.processedItems?.forEach((item) => {
                if (item.assignee?.name) {
                    allAssignees.add(item.assignee.name);
                }
                if (item.supporter?.name) {
                    allAssignees.add(item.supporter.name);
                }
                if (item.product?.name) {
                    allProducts.add(item.product.name);
                }
            });
        });

        return {
            assignees: Array.from(allAssignees),
            customers: Array.from(allCustomers),
            products: Array.from(allProducts),
        };
    }, [data, filters.startMonth, filters.endMonth]); // **只根據日期篩選**



    const handleFilterChange = (key: string, value: any) => {
        setFilters((prev) => {
            let newFilters = { ...prev, [key]: value };

            // ✅ **當修改「開始月份」時，重置 參與人/客戶/產品 並修正結束月份**
            if (key === "startMonth") {
                const startMonthDate = new Date(value);
                const endMonthDate = new Date(prev.endMonth);

                if (startMonthDate > endMonthDate) {
                    // **如果 `startMonth` 超過 `endMonth`，將 `endMonth` 設為 `startMonth + 6 個月`**
                    endMonthDate.setMonth(startMonthDate.getMonth() + 6);
                    newFilters.endMonth = `${endMonthDate.getFullYear()}-${String(endMonthDate.getMonth() + 1).padStart(2, "0")}`;
                }

                // ✅ **修改區間後，清空 `參與人/客戶/產品` 篩選**
                newFilters.assignees = [];
                newFilters.customers = [];
                newFilters.products = [];
            }

            // ✅ **當修改「結束月份」時，修正開始月份**
            if (key === "endMonth") {
                const startMonthDate = new Date(prev.startMonth);
                const endMonthDate = new Date(value);

                if (endMonthDate < startMonthDate) {
                    // **如果 `endMonth` 小於 `startMonth`，將 `startMonth` 設為 `endMonth - 6 個月`**
                    startMonthDate.setMonth(endMonthDate.getMonth() - 6);
                    newFilters.startMonth = `${startMonthDate.getFullYear()}-${String(startMonthDate.getMonth() + 1).padStart(2, "0")}`;
                }
            }

            return newFilters;
        });
    };


    const handleSearchChange = (key: string, value: string) => {
        setSearchQuery((prev) => ({ ...prev, [key]: value }));
    };

    const filteredData = useMemo(() => {
        if (!data?.getOrdersForCsProject) return [];

        const { assignees, customers, products, startMonth, endMonth } = filters;
        const startDate = startMonth ? new Date(getMonthFirstDay(startMonth)) : null;
        const endDate = endMonth ? new Date(getMonthLastDay(endMonth)) : null;

        // **Step 1: 先過濾「符合日期區間」的 `order`**
        let filteredOrders:any = data.getOrdersForCsProject.filter(order =>
            order.processedItems?.some(item => isDateOverlap(item.startDate, item.endDate, startDate, endDate))
        );

        // **Step 2: 進一步篩選 `assignees/customers/products`**
        if (assignees.length > 0 || customers.length > 0 || products.length > 0) {
            filteredOrders = filteredOrders
                .map(order => {
                    const customerMatch = customers.length === 0 || customers.includes(order.customer?.name || "");
                    if (!customerMatch) {
                        return null;
                    }
                    const assignedSalesMatch = assignees.length === 0 || assignees.includes(order.assignedSalesUser?.name || "");

                    // **篩選 `processedItems`**
                    let matchedItems = (order.processedItems || []).filter(item => {
                        const assigneeMatch = assignees.length === 0 || assignees.includes(item.assignee?.name || "") || assignees.includes(item.supporter?.name || "");
                        const productMatch = products.length === 0 || products.includes(item.product?.name || "");

                        return (assigneeMatch || assignedSalesMatch) && productMatch;
                    });

                    // ✅ **如果 `order` 本身沒有符合條件，且 `processedItems` 全部被篩選掉，則移除該 `order`**
                    if (matchedItems.length === 0) {
                        return null;
                    }
                    return { ...order, processedItems: matchedItems };
                })
                .filter(order => order !== null); // **移除 `null` 代表的 `order`**
        }

        return filteredOrders;
    }, [data, filters]);

    // **動態等待 UI 渲染完成後再更新數據**
    useEffect(() => {
        setIsProcessing(true);

        const updateData = () => {
            setComputedData(filteredData);
            setIsProcessing(false);
        };

        if ("requestIdleCallback" in window) {
            // 瀏覽器支援 `requestIdleCallback`
            (window as any).requestIdleCallback(updateData);
        } else {
            // 使用 `setTimeout` + `requestAnimationFrame` 來確保 UI 完成後再更新
            setTimeout(() => requestAnimationFrame(updateData), 0);
        }
    }, [filteredData]);


    return (
        <Page title="CS Project Timeline">
            <Container maxWidth={false} style={{ padding: "30px" }}>
                <Typography variant="h4" gutterBottom>
                    CS Project Timeline
                </Typography>
                <Grid container spacing={3}>
                    {/* 開始月份 */}
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label="開始月份"
                            type="month"
                            value={filters.startMonth}
                            onChange={(e) =>
                                handleFilterChange("startMonth", e.target.value)
                            }
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    {/* 結束月份 */}
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label="結束月份"
                            type="month"
                            value={filters.endMonth}
                            onChange={(e) => handleFilterChange("endMonth", e.target.value)}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    {/* 參與人多選下拉 */}
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth>
                            <InputLabel>參與人</InputLabel>
                            <Select
                                multiple
                                value={filters.assignees}
                                onChange={(e) =>
                                    handleFilterChange("assignees", e.target.value)
                                }
                                renderValue={(selected) => (selected as string[]).join(", ")}
                                MenuProps={{
                                    PaperProps: {
                                        style: { maxHeight: 300 },
                                    },
                                }}
                            >
                                <TextField
                                    placeholder="搜尋參與人"
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    onClick={(e) => e.stopPropagation()}
                                    onKeyDown={(e) => e.stopPropagation()}
                                    onChange={(e) =>
                                        handleSearchChange("assignees", e.target.value)
                                    }
                                    value={searchQuery.assignees}
                                />
                                {dynamicOptions.assignees
                                    .filter((assignee) =>
                                        assignee
                                            .toLowerCase()
                                            .includes(searchQuery.assignees.toLowerCase())
                                    )
                                    .map((assignee) => (
                                        <MenuItem key={assignee} value={assignee}>
                                            <Checkbox
                                                checked={filters.assignees.includes(assignee)}
                                            />
                                            <ListItemText primary={assignee} />
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {/* 客戶多選下拉 */}
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth>
                            <InputLabel>客戶名稱</InputLabel>
                            <Select
                                multiple
                                value={filters.customers}
                                onChange={(e) =>
                                    handleFilterChange("customers", e.target.value)
                                }
                                renderValue={(selected) => (selected as string[]).join(", ")}
                                MenuProps={{
                                    PaperProps: {
                                        style: { maxHeight: 300 },
                                    },
                                }}
                            >
                                <TextField
                                    placeholder="搜尋客戶"
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    onClick={(e) => e.stopPropagation()}
                                    onKeyDown={(e) => e.stopPropagation()}
                                    onChange={(e) =>
                                        handleSearchChange("customers", e.target.value)
                                    }
                                    value={searchQuery.customers}
                                />
                                {dynamicOptions.customers
                                    .filter((customer) =>
                                        customer
                                            .toLowerCase()
                                            .includes(searchQuery.customers.toLowerCase())
                                    )
                                    .map((customer) => (
                                        <MenuItem key={customer} value={customer}>
                                            <Checkbox checked={filters.customers.includes(customer)} />
                                            <ListItemText primary={customer} />
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {/* 產品多選下拉 */}
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth>
                            <InputLabel>產品名稱</InputLabel>
                            <Select
                                multiple
                                value={filters.products}
                                onChange={(e) =>
                                    handleFilterChange("products", e.target.value)
                                }
                                renderValue={(selected) => (selected as string[]).join(", ")}
                                MenuProps={{
                                    PaperProps: {
                                        style: { maxHeight: 300 },
                                    },
                                }}
                            >
                                <TextField
                                    placeholder="搜尋產品"
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    onClick={(e) => e.stopPropagation()}
                                    onKeyDown={(e) => e.stopPropagation()}
                                    onChange={(e) =>
                                        handleSearchChange("products", e.target.value)
                                    }
                                    value={searchQuery.products}
                                />
                                {dynamicOptions.products
                                    .filter((product) =>
                                        product
                                            .toLowerCase()
                                            .includes(searchQuery.products.toLowerCase())
                                    )
                                    .map((product) => (
                                        <MenuItem key={product} value={product}>
                                            <Checkbox checked={filters.products.includes(product)} />
                                            <ListItemText primary={product} />
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                {/* 表格 Loading 狀態 */}
                {isProcessing ? (
                    <Box display="flex" justifyContent="center" alignItems="center" style={{ marginTop: 20 }}>
                        <CircularProgress />
                        <Typography variant="body1" style={{ marginLeft: 10 }}>資料載入中...</Typography>
                    </Box>
                ) : (
                    <Box display="flex" justifyContent="center" alignItems="center" style={{ marginTop: 20 }}>
                        <CsProjectTable filteredData={computedData} startMonth={filters.startMonth} endMonth={filters.endMonth} />
                    </Box>
                )}
            </Container>
        </Page>
    );
};

export default CsProjectView;
