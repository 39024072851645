import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import DepositRecordTable from "./DepositRecordTable";

const DepositRecordButton = ({ deposit }) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
            >
                查看紀錄
            </Button>

            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogContent>
                    <DepositRecordTable deposit={deposit} />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setOpen(false)}
                        color="secondary"
                        variant="contained"
                    >
                        關閉
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DepositRecordButton;
