import { format } from "date-fns";
import React, { useCallback } from "react";
import DefaultQueryHandler from "../../common/DefaultQueryHandler";
import { InputType } from "../../common/FormRenderer";
import NotificationPopup from "../../common/NotificationPopup";
import { selectMapFn } from "../../common/Util";
import { DepositStatus, ProductOrigin, useCreateDepositMutation, useGetProductQuery } from "../../generated/graphql";
import CreateView from "../custom/CreateView";

export default ({ orderItem }) => {
    const [createDeposit] = useCreateDepositMutation();
    const { data, loading, error } = useGetProductQuery({
        variables: {
            id: orderItem.product.id,
        },
    });
    const onSubmit = useCallback(
        async (deposit, { resetForm }, setOpen) => {
            if (deposit.amount > total) {
                NotificationPopup.error(`超過可發稿金額`);
                return;
            }
            try {
                const newDeposit = await createDeposit({
                    variables: {
                        deposit: {
                            orderItemId: orderItem.id,
                            supplierId: deposit.supplierId,
                            accountId: deposit.accountId,
                            amount: deposit.amount,
                            startDate: deposit.startDate,
                            endDate: deposit.endDate,
                            note: deposit.note,
                        },
                    },
                    refetchQueries: ["getOrder", "getOrderItem"],
                });
                NotificationPopup.success(`建立完成`);
                return newDeposit.data?.createDeposit;
            } catch (e) {
                NotificationPopup.error(`新增發稿發生問題: ${e.message}`);
                console.error(e);
            } finally {
                resetForm();
                setOpen(false);
            }
        },
        [createDeposit]
    );
    let total = orderItem.budget;
    orderItem?.deposits.map((deposit) => {
        if (deposit.status == DepositStatus.Finished) {
            total -= deposit.amount;
        }
        else if ([DepositStatus.Pending, DepositStatus.Changed].includes(deposit.status)) {
            if (deposit.changes.length > 0) {
                const lastVerifiedRecord = deposit.changes
                    ?.filter(record => record.verified)
                    .sort((a, b) => b.id - a.id)[0];
                total -= Math.max(deposit.changes[deposit.changes.length - 1].amount, lastVerifiedRecord?.amount ?? 0);
                console.log(Math.max(deposit.changes[deposit.changes.length - 1].amount, lastVerifiedRecord?.amount ?? 0));
            }
        }
    });
    return (
        <CreateView
            title={"新增發稿"}
            columns={[
                {
                    name: "id",
                    label: "",
                    type: InputType.custom,
                    renderCell: (
                        <div style={{ marginBottom: 10 }}>
                            發稿可用金額：{total?.toLocaleString()}
                        </div>
                    ),
                },
                {
                    name: "supplierId",
                    label: "供應商",
                    type: InputType.select,
                    options: data ? data.product.suppliers!.map(selectMapFn) : [],
                },
                { name: "accountId", label: "CID" },
                // { name: "accountId", label: "CID", pattern: orderItem.product.productOrigin == ProductOrigin.Facebook ? "\\d{15}" : orderItem.product.productOrigin == ProductOrigin.Google ? "\\d{10}" : null},
                { name: "amount", label: "發稿金額", type: InputType.number },
                {
                    name: "startDate",
                    label: "開始時間",
                    type: InputType.date,
                    min: orderItem.startDate,
                    max: orderItem.endDate,
                },
                {
                    name: "endDate",
                    label: "結束時間",
                    type: InputType.date,
                    min: orderItem.startDate,
                    max: orderItem.endDate,
                },
                {
                    name: "note",
                    label: "備註",
                }
            ]}
            onSubmit={onSubmit}
            queryEntity={"deposit"}
        />
    );
};
