import { Box } from "@material-ui/core";
import React from "react";
import PaginatedDatagrid, { FilterButton } from "../../components/PaginatedDatagrid";
import { DepositStatus } from "../../generated/graphql";
import {DepositStatusMap, DepositStatusList} from "../../common/Constant";
import UpdateDeposit from "./UpdateDeposit";
import { loader } from "graphql.macro";
import { format } from "date-fns";
import {GridCellParams, GridFilterItem, GridValueFormatterParams, GridValueGetterParams} from "@mui/x-data-grid-pro";
import AuditDepositButton from "./AuditDepositButton";
import DepositRecordButton from "./DepositRecordButton";
const GetDepositList = loader("./graphql/GetDepositList.graphql");

export default ({defaultFilterItems, filterButtons, refetchState}: 
    {defaultFilterItems?: GridFilterItem[], filterButtons?: FilterButton[], refetchState?: any}) => {
    return (
        <Box width={"100%"}>
            <PaginatedDatagrid
                id={'deposit'}
                refetchState={refetchState}
                defaultFilterItems={defaultFilterItems}
                filterButtons={filterButtons}
                query={GetDepositList}
                columns={[
                    {
                        headerName: "ID",
                        field: "id",
                        type: "number",
                    },
                    {
                        headerName: "委刊單號",
                        field: "orderItem.order.number",
                        renderCell: (params: GridCellParams) => {
                            return <a href={`/app/order/${params.row.orderItem.order.id}`}>{params.row.orderItem.order.number}</a>;
                        }
                    },
                    {
                        headerName: "細項ID",
                        field: "orderItem.id",
                        renderCell: (params: GridCellParams) => {
                            return <a href={`/app/orderItem/${params.row.orderItem.id}`}>{params.row.orderItem.id}</a>;
                        }
                    },

                    {
                        headerName: "客戶",
                        field: "orderItem.order.customer.name",
                    },

                    {
                        field: "supplier.name",
                        headerName: "供應商",
                    },
                    {
                        headerName: "CID",
                        field: "accountId",
                    },
                    {
                        headerName: "發稿金額(審核中金額)",
                        field: "amount",
                        valueGetter: (params: GridValueGetterParams) => {
                            return (
                                params.row.amount?.toLocaleString() +
                                ([DepositStatus.Pending, DepositStatus.Changed].includes(params.row.status) && params.row.changes.length > 0
                                    ? `(${params.row.changes[params.row.changes.length-1].amount})`
                                    : '')
                            );
                        },
                    },
                    {
                        headerName: "備註",
                        field: "note",
                    },
                    {
                        headerName: "狀態",
                        field: "status",
                        valueGetter: (params: GridValueGetterParams) => {
                            return DepositStatusMap[params.row.status as string];
                        },
                        valueOptions: DepositStatusList,
                        type: "singleSelect",
                    },
                    {
                        headerName: "開始日期",
                        field: "startDate",
                        valueGetter: (params: GridValueGetterParams) =>
                            params.row.startDate ? format(new Date(params.row.startDate as string), "yyyy-MM-dd") : '未指定',
                        type: "date",
                    },
                    {
                        headerName: "結束日期",
                        field: "endDate",
                        valueGetter: (params: GridValueGetterParams) =>
                        params.row.endDate ? format(new Date(params.row.endDate as string), "yyyy-MM-dd") : '未指定',
                        type: "date",
                    },
                    {
                        headerName: "AM",
                        field: "orderItem.assignee.name",
                    },
                    {
                        headerName: "AE",
                        field: "orderItem.order.assignedSalesUser.name",
                    },
                    {
                        headerName: "異動紀錄",
                        field: "record",
                        width: 150,
                        renderCell: (params) => <DepositRecordButton deposit={params.row} />,
                    },
                    {
                        headerName: "動作",
                        field: "actions",
                        disableColumnMenu: true,
                        sortable: false,
                        renderCell: (params: GridCellParams) => {
                            return [DepositStatus.Pending, DepositStatus.Changed].includes(params.row.status) ? <AuditDepositButton deposit={params.row}/> : <div></div>
                            // return <UpdateDeposit deposit={params.row} amMode={false} orderItem={params.row.orderItem}/>;
                        },
                    },
                ]}
            />
        </Box>
    );
};
