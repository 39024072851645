import { Box, Button, Grid } from "@material-ui/core";
import React, { useCallback } from "react";
import { loader } from "graphql.macro";
import PaginatedDatagrid, {
    FilterButton,
} from "../../components/PaginatedDatagrid";
import { saveFile, ViewColumn } from "../../common/Util";
import { InvoiceItem } from "../../generated/graphql";
import {OrderItemTypeMap, OrderStatusList, OrderStatusMap, ProductOriginList} from "../../common/Constant";
import {GridCellParams, GridFilterItem, GridValueGetterParams} from "@mui/x-data-grid-pro";
import { format } from "date-fns";
import ReportDateInput, { useReportDate } from "../report/ReportDateInput";
import { getAccessToken } from "../../Auth";
import { saveAs } from "file-saver";
import CreateView from "../custom/CreateView";
import { InputType } from "../../common/FormRenderer";
import { map } from "lodash";
import { download as downloadReport } from "../report/DownloadableReports"
const GetOrderItemListQuery = loader("./graphql/GetOrderItemList.graphql");

export default ({
    defaultFilterItems,
    filterButtons,
}: {
    defaultFilterItems?: GridFilterItem[];
    filterButtons?: FilterButton[];
}) => {
    const { reportDate, onChange } = useReportDate();
    const download = useCallback(async () => {
        const res = await fetch(
            `${process.env.REACT_APP_BACKEND_URI}/reports/ae-overview?year=${reportDate.year}&month=${reportDate.month}`,
            {
                headers: {
                    Authorization: `Bearer ${getAccessToken()}`,
                },
            }
        );
        const blob = await res.blob();
        saveAs(blob, `cid-${reportDate.year}-${reportDate.month}.xlsx`);
    }, [reportDate]);
    return (
        <Box width={"100%"}>
            <Grid container spacing={2}>
                <Grid item>
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        onClick={() => {
                            saveFile(
                                `/order-items/finished-order-items`,
                                `已結案委刊細項報表-${reportDate.year}-${reportDate.month}.xlsx`
                            );
                        }}
                    >
                        下載已結案報表
                    </Button>
                </Grid>
                <Grid item>
                    <CreateView
                        submitTitle={"下載報表"}
                        title={"下載委刊細項報表"}
                        columns={[
                            {
                                name: "startDate",
                                label: "走期(起)",
                                type: InputType.date,
                            },
                            {
                                name: "endDate",
                                label: "走期(迄)",
                                type: InputType.date,
                            },
                            {
                                name: "productOrigin",
                                label: "媒體別(原廠)",
                                type: InputType.select,
                                options: [
                                    {value: "all", label: "全選"},
                                ].concat(ProductOriginList),
                            },
                        ]}
                        onSubmit={async (res, helpers, setOpen) => {
                            await downloadReport(() => setOpen(false),
                                `reports/order-items?startDate=${
                                    res.startDate
                                }&endDate=${res.endDate}&productOrigin=${
                                    res.productOrigin === "all"
                                        ? ""
                                        : res.productOrigin
                                }`,
                                "order-items"
                            );
                        }}
                        queryEntity={""}
                    />
                </Grid>
                <Grid item>
                    <ReportDateInput value={reportDate} onChange={onChange} />
                </Grid>
                <Grid item>
                    <Button
                        variant={"contained"}
                        color={"secondary"}
                        onClick={() => download()}
                    >
                        下載花費報表
                    </Button>
                </Grid>
            </Grid>
            <PaginatedDatagrid
                id={'order-item'}
                queryParams={true}
                filterButtons={filterButtons}
                query={GetOrderItemListQuery}
                ordering={{ field: "id", sort: "desc" }}
                columns={[
                    {
                        headerName: "委刊單號",
                        field: "order.number",
                    },
                    {
                        headerName: "AE",
                        field: "order.assignedSalesUser.name",
                    },
                    {
                        headerName: "客戶名稱",
                        field: "order.customer.name",
                    },
                    {
                        headerName: "專案名稱",
                        field: "order.name",
                    },
                    {
                        headerName: "委刊細項ID",
                        field: "id",
                    },
                    {
                        headerName: "AM",
                        field: "assignee.name",
                    },
                    {
                        headerName: "產品類別",
                        field: "product.name",
                    },
                    {
                        headerName: "操作方式",
                        field: "type",
                        valueGetter: (params: GridValueGetterParams) => {
                            return OrderItemTypeMap[params.row.type as string];
                        },
                    },
                    {
                        headerName: "購買金額",
                        field: "totalAmount",
                        valueGetter: (params) =>
                            (params.row.totalAmount as string)?.toLocaleString(),
                    },
                    {
                        headerName: "帳戶執行費",
                        field: "serviceFee",
                        valueGetter: (params) =>
                            (params.row.serviceFee as string)?.toLocaleString(),
                    },
                    {
                        headerName: "預估專案成本",
                        field: "budget",
                        valueGetter: (params) =>
                            (params.row.budget as string)?.toLocaleString(),
                    },
                    {
                        headerName: "供應商",
                        field: "deposits.supplier",
                        valueGetter: (params) => {
                            let supplierList = params.row.deposits.map(
                                (deposit) => {
                                    return deposit.supplier?.name;
                                }
                            );
                            return supplierList.join(",");
                        },
                    },
                    {
                        headerName: "CID",
                        field: "deposits.accountId",
                        valueGetter: (params) => {
                            let cidList = params.row.deposits.map((deposit) => {
                                return deposit.accountId;
                            });
                            return cidList.join(",");
                        },
                    },
                    {
                        headerName: "走期開始日",
                        field: "deposits.startDate",
                        valueGetter: (params) => {
                            let startDateList = params.row.deposits.map(
                                (deposit) => {
                                    return format(
                                        new Date(deposit.startDate),
                                        "yyyy-MM-dd"
                                    );
                                }
                            );
                            return startDateList.join(",");
                        },
                    },
                    {
                        headerName: "走期結束日",
                        field: "deposits.endDate",
                        valueGetter: (params) => {
                            let endDateList = params.row.deposits.map(
                                (deposit) => {
                                    return format(
                                        new Date(deposit.endDate),
                                        "yyyy-MM-dd"
                                    );
                                }
                            );
                            return endDateList.join(",");
                        },
                    },
                    {
                        headerName: "發稿金額",
                        field: "deposits.amount",
                        valueGetter: (params) => {
                            let amountList = params.row.deposits.map(
                                (deposit) => {
                                    return deposit.amount;
                                }
                            );
                            return amountList.join(",");
                        },
                    },
                    {
                        headerName: "發稿總金額",
                        field: "deposits.totalAmount",
                        valueGetter: (params) => {
                            let total = params.row.deposits?.reduce(
                                (a, c) =>
                                    a +
                                    parseFloat(c.amount),
                                0
                            );
                            console.log(total);
                            return total?.toLocaleString();
                        },
                    },
                    {
                        headerName: "委刊細項花費",
                        field: "deposits.accountCosts",
                        valueGetter: (params) => {
                            let totalCost = 0;
                            params.row.deposits.map((deposit) => {
                                deposit.accountCosts.map((accountCost) => {
                                    totalCost += accountCost.cost;
                                });
                            });
                            return totalCost?.toLocaleString();
                        },
                    },
                    {
                        headerName: "發票開立金額",
                        field: "invoiceItems",
                        valueGetter: (params) => {
                            let amount = 0;
                            let invoiceItems = params?.row.invoiceItems as [InvoiceItem];
                            invoiceItems?.map((invoiceItem) => {
                                amount += invoiceItem.amount;
                            });
                            return amount?.toLocaleString();
                        },
                    },
                    {
                        headerName: "專案毛利",
                        field: "benifit",
                        valueGetter: (params) => {
                            let depositAmount = 0;
                            params.row.deposits.map((deposit) => {
                                depositAmount += deposit.amount;
                            });

                            return (
                                params.row.totalAmount - depositAmount
                            )?.toLocaleString();
                        },
                    },
                    {
                        headerName: "狀態",
                        field: "status",
                        valueGetter: (params: GridValueGetterParams) => {
                            return OrderStatusMap[params.row.status as string];
                        },
                        type: "singleSelect",
                        valueOptions: OrderStatusList,
                    },
                    ViewColumn("/app/orderItem"),
                ]}
                defaultFilterItems={defaultFilterItems}
                defaultPageSize={15}
            />
        </Box>
    );
};
