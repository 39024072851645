import React from "react";
import { Navigate } from "react-router-dom";
import DashboardLayout from "./layouts/DashboardLayout";
import MainLayout from "./layouts/MainLayout";
import NotFoundView from "./views/errors/NotFoundView";
import UserListView from "./views/user/UserListView";
import OrderView from "./views/order/OrderView";
import OrderDetailView from "./views/order/OrderDetailView";
import CustomerListView from "./views/customer/CustomerListView";
import CustomerDetailView from "./views/customer/CustomerDetailView";
import DefaultDashboard from "./views/dashboard/DefaultDashboardView";
import ProductView from "./views/product/ProductView";
import SupplierView from './views/supplier/SupplierView'
import OrderItemView from "./views/orderItem/OrderItemView";
import OrderItemDetailView from "./views/orderItem/OrderItemDetailView";
import ProductDetailView from "./views/product/ProductDetailView";
import SupplierDetailView from './views/supplier/SupplierDetailView';
import InvoiceView from "./views/invoice/InvoiceView";
import InvoiceDetailView from "./views/invoice/InvoiceDetailView";
import ReportView from "./views/report/ReportView";
import DepositView from "./views/deposit/DepositView";
import {PartialRouteObject} from "react-router";
import PurchaseOrderView from "./views/purchaseOrder/PurchaseOrderView";
import PurchaseOrderDetailView from "./views/purchaseOrder/PurchaseOrderDetailView";
import ReceivedPaymentView from "./views/receivedPayment/ReceivedPaymentView";
import ReceivedPaymentDetailView from "./views/receivedPayment/ReceivedPaymentDetailView";
import IndustryReportView from "./views/industryReport/IndustryReportView";
import LogView from "./views/log/LogView";
import UpgradeView from "./views/upgrade/UpgradeView";
import UpgradeDetailView from "./views/upgrade/UpgradeDetailView";
import TemplateView from "./views/googleAds/template/TemplateView";
import RecordView from "./views/googleAds/record/RecordView";
import CsProjectView from "./views/csProject/CsProjectView";

const routes:PartialRouteObject[] = [
    {
        path: "app",
        element: <DashboardLayout />,
        children: [
            // { path: "account", element: <AccountView /> },
            { path: "*", element: <Navigate to="/404" /> },
            { path: "admin", element: <UserListView /> },
            { path: "order", element: <OrderView/> },
            { path: "customer", element: <CustomerListView/> },
            { path: "customer/:id", element: <CustomerDetailView/> },
            { path: "order/:id", element: <OrderDetailView/> },
            { path: "dashboard", element: <DefaultDashboard/> },
            { path: "product", element: <ProductView/> },
            { path: "product/:id", element: <ProductDetailView/> },
            { path: "supplier", element: <SupplierView/>},
            { path: "supplier/:id", element: <SupplierDetailView/> },
            { path: "orderItem", element: <OrderItemView/>},
            { path: "orderItem/:id", element: <OrderItemDetailView/>},
            { path: "invoice", element: <InvoiceView/>},
            { path: "invoice/:id", element: <InvoiceDetailView/>},
            { path: "report/order-item",element:<ReportView />},
            { path: "deposit", element: <DepositView/>},
            { path: "purchaseOrder", element: <PurchaseOrderView/>},
            { path: "purchaseOrder/:id", element: <PurchaseOrderDetailView/>},
            { path: "receivedPayment", element: <ReceivedPaymentView/> },
            { path: "receivedPayment/:id", element: <ReceivedPaymentDetailView/> },
            { path: "industryReport", element: <IndustryReportView/>},
            { path: "template", element: <TemplateView/>},
            { path: "record", element: <RecordView/>},
            { path: "log", element: <LogView/>},
            { path: "upgrade", element: <UpgradeView/>},
            { path: "upgrade/:id", element: <UpgradeDetailView/>},
            { path: "cs-project", element: <CsProjectView/>},
        ],
    },
    {
        path: "/",
        element: <MainLayout />,
        children: [
            // { path: 'login', element: <LoginView /> },
            // { path: 'register', element: <RegisterView /> },
            { path: "404", element: <NotFoundView /> },
            { path: "/", element: <Navigate to="/app/dashboard" /> },
            { path: "*", element: <Navigate to="/404" /> },
        ],
    },
];

export default routes;
